<template>
  <div>
    <b-card no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <b-col cols="12" md="3">
            <b-form-group label="Product" label-for="name" class="mb-0">
              <v-select
                v-model="search.sport_type_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="codeProduct"
                :searchable="false"
                class="w-100"
                :reduce="(val) => val.value"
              />

              <div style="margin: 8px 0px; color: red" v-if="isProductId">
                The field is require
              </div>
              <!-- :clearable="false" -->
              <!-- input-id="category" -->
            </b-form-group>
          </b-col>
          <b-col cols="12" md="4" lg="3" sm="6" class="mb-0 ipad">
            <b-form-group :label="$t('From date')" label-for="fromDate">
              <flat-pickr
                id="fromDate"
                v-model="search.fromdate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isFromDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>

          <b-col cols="12" md="4" lg="3" sm="6" class="mb-0 ipad">
            <b-form-group :label="$t('To date')" label-for="toDate">
              <flat-pickr
                id="toDate"
                v-model="search.todate"
                class="form-control"
                placeholder="YYYY-MM-DD"
              />
              <div style="margin: 8px 0px; color: red" v-if="isToDate">
                The field is require
              </div>
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="4" lg="3" sm="6"
            class="justify-content-start"
            style="padding-top: 20px"
          >
            <b-button variant="outline-primary" @click="resetFilter()">
              Reset
            </b-button>
            <b-button variant="primary" class="ml-2" @click="searchFilter()">
              Search
            </b-button>
          </b-col>
        </b-row>
        <b-row class="d-flex align-items-end justify-content-end">
          
        </b-row>
      </div>
    </b-card>
    <b-card no-body>
      <!-- <div class="m-2">
        <b-row>
          <b-col cols="12" v-if="$can('create', 'sportteam')">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showAddSportTeamModal()">
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> New
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div> -->
      <b-table
        ref="refSportTeamListTable"
        class="position-relative table-white-space mb-0 max-height-table"
        sticky-header
        head-variant="light"
        :items="transformedArray"
        responsive
        :fields="tableColumns"
        primary-key="index"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>
        <template #cell(status)="data">
          <b-link class="cursor-pointer text-primary">
            <span class="text-nowrap">
              {{
                data.item.status
                  ? resolveTypeStatus(data.item.status).label
                  : ""
              }}
            </span>
          </b-link>
        </template>

        <template #cell(amount)="data">
          <b-link class="cursor-pointer text-primary">
            {{ data.item.amount ? numberFormat(data.item.amount / 1000) : "" }}
          </b-link>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalSportTeam"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BImg,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import { ref, watch, computed, onUnmounted } from "@vue/composition-api";
import { formatDateTime, numberFormat } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import baseUrl from "@/libs/base-url";
import sportsStoreModule from "@/views/sports/sportsStoreModule";
import AddSportsTeamModal from "@/views/sports/AddSportsTeam.vue";
import UpdateSportTeamModal from "@/views/sports/UpdateSportsTeam.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    flatPickr,
    // AddsportTeamModal,
    // EditsportTeamModal,
    AddSportsTeamModal,
    UpdateSportTeamModal,
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      sportTeamDetail: null,
    };
  },
  methods: {},
  setup() {
    if (!store.hasModule("sports"))
      store.registerModule("sports", sportsStoreModule);
    onUnmounted(() => {
      if (store.hasModule("sports")) store.unregisterModule("sports");
    });

    const toast = useToast();

    const search = ref({
      sport_type_id: "",
      fromdate: "",
      todate: "",
    });
    const resolveTypeStatus = (status) => {
      if (status == 1) return { label: "place bet", variant: "success" };
      if (status == 2) return { label: "win", variant: "secondary" };
      if (status == 3) return { label: "loss", variant: "Primary" };
      if (status == 4) return { label: "cancel", variant: "Warning" };
      if (status == 5) return { label: "tie", variant: "Danger" };
      return { label: "None", variant: "secondary" };
    };
    // Status: 1:place bet, 2: win 3: loss, 4:cancel, 5: tie (lưu í nếu status nào k có thì hiển thị trống)
    const sportTypeId = ref([]);
    const sportTypeIdFilter = ref(null);
    const toDateFilter = ref(null);
    const fromDateFilter = ref(null);
    const codeProduct = ref([]);
    const perPage = ref(25);
    const totalSportTeam = ref(0);
    const currentPage = ref();
    const perPageOptions = [10, 25, 50, 100];
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const isProductId = ref(false);

    const refSportTeamListTable = ref(null);
    const isFromDate = ref(false);
    const isToDate = ref(false);
    const tableColumns = [{ key: "index", label: "#" }, "status", "amount"];

    const refetchData = () => {
      refSportTeamListTable.value.refresh();
    };

    watch(
      [currentPage, toDateFilter, sportTypeIdFilter, fromDateFilter],
      () => {
        refetchData();
      }
    );
    const dataAccountWinLoss = ref();
    const desiredStatuses = ref([1, 2, 3, 4, 5]);
    const transformedArray = ref();
    const sportTeamLists = (ctx, callback) => {
      store
        .dispatch("sports/fetchAccountingWinLoss", {
          productId: sportTypeIdFilter.value,
          fromDate: fromDateFilter.value,
          toDate: toDateFilter.value,
          page: currentPage.value,
        })
        .then((response) => {
          // callback(response.sportTeam);
          dataAccountWinLoss.value = response.sportTeam;
          totalSportTeam.value = response.total;
          perPage.value = response.pageSize;
          transformedArray.value = desiredStatuses.value.map((status) => {
            const item = dataAccountWinLoss.value.find(
              (obj) => obj.status === status
            );

            if (item) {
              return item;
            } else {
              return { amount: "", status };
            }
          });
          console.log(123, transformedArray.value);
        });
    };
    // sportTeamLists();
    store.dispatch("sports/fetchBonusCodeProduct", {}).then((response) => {
      codeProduct.value = response.data.data.map((val) => {
        return {
          label: val.name,
          value: val.id,
        };
      });
    });

    const dataMeta = computed(() => {
      const localItemsCount = refSportTeamListTable.value
        ? refSportTeamListTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalSportTeam.value,
      };
    });

    const resetFilter = () => {
      // search.value.name = "";
      search.value.sport_type_id = "";
      search.value.fromdate = "";
      search.value.todate = "";
      toDateFilter.value = null;
      fromDateFilter.value = null;
      sportTypeIdFilter.value = null;
      sportTeamLists();
    };

    const searchFilter = () => {
      // toDateFilter.value = search.value.name;
      // toDateFilter.value = search.value.todate;
      // fromDateFilter.value = search.value.fromdate;
      // sportTypeIdFilter.value = search.value.sport_type_id;
      if (search.value.sport_type_id == "") {
        isProductId.value = true;
      }
      if (search.value.fromdate == "") {
        isFromDate.value = true;
      }
      if (search.value.todate == "") {
        isToDate.value = true;
      }

      if (
        search.value.fromdate !== "" &&
        search.value.todate !== "" &&
        search.value.sport_type_id !== ""
      ) {
        isFromDate.value = false;
        isToDate.value = false;
        isProductId.value = false;
        sportTypeIdFilter.value = search.value.sport_type_id;
        toDateFilter.value = search.value.todate;
        fromDateFilter.value = search.value.fromdate;
      }
      sportTeamLists();
    };

    return {
      isProductId,
      codeProduct,
      tableColumns,
      perPage,
      currentPage,
      totalSportTeam,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refSportTeamListTable,

      sportTeamLists,
      refetchData,
      toDateFilter,
      search,

      resetFilter,
      sportTypeIdFilter,

      // resolveStatus,
      // statusOptions,
      numberFormat,

      formatDateTime,
      searchFilter,
      baseUrl,
      sportTypeId,
      fromDateFilter,
      isFromDate,
      isToDate,
      dataAccountWinLoss,
      resolveTypeStatus,
      transformedArray,
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
